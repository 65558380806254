@import '../../variables';

.card-row {
    display: grid;
    grid-gap: $gutter-width;
    grid-template-columns: repeat(12, [col-start] 1fr);

    &:nth-child(n + 2) {
        margin-top: $gutter-width / 2;

        @media (min-width: 600px) {
            margin-top: $gutter-width;
        }

        @media (min-width: 1024px) {
            margin-top: $gutter-width * 2;
        }
    }

    // Grid layout alternative for IE using table layout to fit cards on the row.
    // This has a limitation that the cards will be the same size within the row
    // as there is no way to set the width in a predictable way. Once IE use drops
    // off a bit more this can be removed.
    @media all and (-ms-high-contrast: none) {
        display: flex;
        width: 100%;
    }
}
