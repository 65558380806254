@import '../../variables';

.confirmationContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    opacity: 1;

    animation: scale-out 1s ease;

    &.warn {
        background-color: $warn-color;

        .confirmButton { color: $warn-color; }
    }

    &.success {
        background-color: $success-color;

        .confirmButton { color: $success-color; }
    }

    &.error {
        background-color: $error-color;

        .confirmButton { color: $error-color; }
    }

    &.info {
        background-color: $info-color;

        .confirmButton { color: $info-color; }
    }

    &.closing {
        animation: fade-out 0.5s ease;
        opacity: 0;
    }

    @keyframes scale-out {
        0% {
            opacity: 0;
            transform: scaleX(0);
            transform-origin: 50% 50%;
        }
        10% {
            opacity: 0;
        }
        35% {
            opacity: 0.5;
        }
        100% {
            opacity: 1;
            transform: scaleX(1);
            transform-origin: 50% 50%;
        }
    }
    
    @keyframes fade-out {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}

.confirmationInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ($gutter-width / 2) $gutter-width;
    color: #fff;
    font-size: 1.1em;

    animation: fade-in 1s cubic-bezier(0.755, 0.05, 0.855, 0.06);

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.closeIcon {
    width: 24px;
    height: 24px;
    fill: #FFF;
}

.typeIcon {
    width: 22px;
    height: 22px;
    fill: #FFF;

    &.info > circle {
        fill: none;
        stroke: currentColor;
        stroke-width: 2;
    }
}

.iconContainer {
    height: 22px;
    flex: 0 0 50px;
}

.message {
    flex: 1 1 20%;

    & > h3 {
        color: #FFF;
        line-height: 0.5em;
        margin: 0.1em 0;
        padding: 0.1em;
    }
}

.actions {
    display: flex;
    align-items: center;

    button + button {
        margin-left: ($gutter-width / 2);
    }
}

.confirmButton {
    background-color: #FFF;
}

.cancelButton {
    color: #FFF;

    &:hover {
        background-color: transparent;
    }
}

.closeButton {
    height: 24px;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
}
