@import '../../variables';

.docTray {
    position: relative;
    height: 100%;
    background: #fbfbfb;
    box-shadow: -10px 10px 10px 0px rgba(0, 0, 0, 0.16);
    transition: 1s ease;

    &.hidden {
        transform: translateX(105%);
    }

    &.visible {
        transform: translateX(0);
    }
}

.docTrayHeader {
    background: #fff;
    height: 130px;
    display: flex;
    align-items: center;
    padding: $gutter-width;
    z-index: 2;
    border-bottom: 2px solid #EEEEEE;
}

.docTrayContent {
    position: absolute;
    top: 130px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $gutter-width;
}

.title {
    font-size: 18px;
    font-weight: 600;
    color: #555555;
    padding-left: 19px;
}

.backButton {
    position: absolute;
    top: 0px;
    left: 3px;
    padding: 5px;
    background: none;
    border: none;
}

.closeButton {
    display: block;
    width: 24px;
    height: 24px;
    fill: #878787;
    margin: 5px;
}
