@import '../../variables';

.container, .innerContainer {
    // This gives some space for the shadows to appear without
    // creating scrollbars or making the hidden cards visible.
    padding: 10px;
    margin: -10px;
}

.container {
    position: relative;
}

.innerContainer {
    overflow: hidden;
}

.cardWrapper {
    display: flex;
    width: 100%;
    transition: transform ease $ui-animation-duration;
    transform: translateX(0px);
}

.staticCardWrapper {
    composes: cardWrapper;
    transition: none;
}

.cardContainer {
    $small-width: 100%;
    $medium-width: calc(50% - #{$gutter-width / 2});
    $large-width: calc(#{(100% / 3)} - #{($gutter-width * 2) / 3});
    $xl-width: calc(25% - #{$gutter-width * 0.75});

    max-width: $small-width;
    flex: 0 0 $small-width;

    @media (min-width: 600px) {
        max-width: $medium-width;
        flex: 0 0 $medium-width;
    }

    @media (min-width: 960px) {
        max-width: $large-width;
        flex: 0 0 $large-width;
    }

    @media (min-width: 1140px) {
        max-width: $xl-width;
        flex: 0 0 $xl-width;
    }

    &:nth-child(n + 2) {
        margin-left: $gutter-width;
    }
}

.buttonContainer {
    display: flex;
    position: absolute;
    top: -10px;
    right: 10px;
    transform: translateY(-50%);

    @media (min-width: 600px) {
        top: -20px;
    }
}

.button {
    display: block;
    background: none;
    border: none;
    margin: 0px;
    padding: 0px;

    & + & {
        margin-left: 3px;
    }

    &::-moz-focus-inner {
        border: none;
        padding: 0px;
    }

    &:focus {
        background-color: $tab-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: inherit;
    }
}

.iconLeft, .iconRight {
    display: block;
    width: 24px;
    height: 24px;
    fill: #666666;
    transition: fill ease $ui-animation-duration;
    margin-bottom: 1px;

    :focus & {
        fill: #000000;
    }

    :disabled & {
        cursor: not-allowed;
        opacity: 0.25;
    }
}

.iconLeft {
    transform: rotate(90deg);
}

.iconRight {
    transform: rotate(270deg);
}
