@import '../../../variables';

.buttonLink {
    display: block;
    font-weight: 500;
    font-size: 1.2em;
    text-decoration: none;
    color: #652D90;
    border: none;
    background-color: none;
    background: none;

    &:nth-child(n + 2) {
        margin-top: 20px;
    }

    &:focus {
        background-color: $tab-color;
        color: $tab-text-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: none;
        background: none;
        color: #652D90;
    }
}
