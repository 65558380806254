@import './variables';
@import './typography';

html {
    box-sizing: border-box;
    min-width: 768px;
}

*, *::before, *::after {
    box-sizing: inherit;
}

html, body {
    margin: 0px;
    padding: 0px;
}

button, input[type="button"], input[type="submit"] {
    font: inherit;
    cursor: pointer;

    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }
}
