.icon {
  color: #858585;
  width: 20px;
  height: 20px;

  &:hover {
    cursor: pointer;
  }

  &.back {
    width: 25px;
    height: 25px;
  }
}
