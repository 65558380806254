@import "../../../variables";

.sectionHeading {
    margin-top: $gutter-width;
    font-weight: 500;
    color: #848484;

    @media (min-width: 960px) {
        margin-top: ($gutter-width * 1.5);
    }

    @media (min-width: 1140px) {
        margin-top: ($gutter-width * 2);
    }
}

.helpText {
    color: #999;
}

.summaryList {
    display: flex;

    dt, dd {
        margin: 0px;
    }

    > div {
        display: flex;
        flex-direction: column;

        &:nth-child(n + 2) {
            margin-left: 30px;
        }
    }

    dt {
        color: #b4b4b4;
        font-weight: 500;
    }

    dd {
        margin-top: 10px;
        color: #666;
    }
}

.paydateTable {
    width: auto;

    th, td {
        &:nth-child(n + 2) {
            padding-left: 60px;
        }
    }
}

.releaseDateCell {
    vertical-align: bottom;

    .time {
        color: #999;
        margin-left: 0.5em;
        margin-right: 1em;
        font-size: 0.9em;
    }
}

.actionButtons {
    margin-top: $gutter-width;

    @media (min-width: 960px) {
        margin-top: ($gutter-width * 1.5);
    }

    @media (min-width: 1140px) {
        margin-top: ($gutter-width * 2);
    }
}

.secondaryButton {
    margin-left: $gutter-width;
}
