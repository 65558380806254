.hideSmall {
    display: none;

    @media (min-width: 600px) {
        display: table-cell;
    }
}

.hideMedium {
    display: none;

    @media (min-width: 960px) {
        display: table-cell;
    }
}

.hideLarge {
    display: none;

    @media (min-width: 1140px) {
        display: table-cell;
    }
}
