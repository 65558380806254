@import '../../../variables';
@import '../../../helpers';

.button {
    position: absolute;
    bottom: $gutter-width;
    display: inline-block;
    composes: default-button from '../../../components/forms/controls/DefaultButton.scss';
    text-decoration: none;
    padding: 0;
    text-transform: uppercase;
}

.buttonLink {
    composes: buttonLink from '../../../components/tables/data-table/DataTable.module.scss';

    &:focus {
        background-color: $tab-color;
        color: $tab-text-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: none;
        background: none;
        color: #652D90;
    }
}

.viewAllButton {
    padding-top: 20px;
}
