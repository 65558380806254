@import '../../variables';

.frame {
    width: 100%;
    height: 100%;
    box-shadow: $container-shadow;
    border-radius: 4px;
    overflow: hidden;

    &.light {
        background: #fff;
    }

    &.dark {
        background: $grey-light;
        color: #fff;
    }

    &.accent {
        background: $accent-color;
        color: #fff;
    }
}
