@import '../../../variables';

.frame {
    transition: background-color ease $ui-animation-duration * 2;
    color: #fff;
    background-color: $accent-color;

    &:hover {
        background-color: $grey-dark;
        transition-duration: $ui-animation-duration;
    }

    &.actionFrame {
        cursor: pointer;
    }
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.innerContainer {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: ($gutter-width / 2) 0;

    &:focus {
        background-color: $tab-color;
    }

    @media (min-width: 600px) {
        padding: ($gutter-width * 1.5) 0;
    }
}

.textContainer {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
    font-size: 1.8em;
    text-transform: uppercase;
    line-height: 1em;
    align-items: center;

    :focus & {
        color: #000000;
    }

    @media (min-width: 960px) {
        flex-wrap: nowrap;
    }
}

.icon {
    display: block;
    flex: 0 0 100%;
    width: 2.5em;
    height: 2.5em;
    margin: 0 0 ($gutter-width / 2);
    fill: #fff;

    :focus & {
        fill: #000000;
    }

    @media (min-width: 960px) {
        flex: initial;
        // hack to ensure consistent display in IE11 compared with Chrome
        -ms-flex-preferred-size: auto;
        width: 1.25em;
        height: 1.25em;
        margin: 0 10px 0 0;
    }
}

