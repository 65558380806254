@import './variables';

body {
    color: $base-font-color;
    font-size: $base-font-size;
    font-family: $base-font-family;
    line-height: $base-line-height;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-weight: bold;
    margin: 0 0 0.75em;
    color: $heading-font-color;
    font-weight: $heading-font-weight;
    font-family: $heading-font-family;
    line-height: $heading-line-height;
}

h1, .h1 { font-size: 1.5em; }
h2, .h2 { font-size: 1.3em; }
h3, .h3 { font-size: 1.125em; }
h4, .h4,
h5, .h5,
h6, .h6 { font-size: $base-font-size; }

@media (min-width: 960px) {
    h1, .h1 { font-size: 2em; }
    h2, .h2 { font-size: 1.5em; }
    h3, .h3 { font-size: 1.17em; }
}
