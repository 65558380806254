@import '../../../variables';

.propertyContainer {
    transition: background-color ease $ui-animation-duration * 2;
    color: #fff;
    background-color: $grey-dark;
}

.propertyContainerWithAction {
    @extend .propertyContainer;

    .propertyFrame:hover & {
        background-color: $accent-color;
        transition-duration: $ui-animation-duration;
    }
}

.buttonContainer {
    margin-top: 20px;
}

.showMore {
    font-family: roboto-condensed;
    font-weight: 500;

    &:focus {
        background-color: $tab-color;
        box-shadow: 0 0 4px 0 $tab-color;
        color: #000000;
    }
}
