@import '../../../variables';

.preview h1 {
    font-size: 1.75em;
}

.subjectTextInput {
    font-weight: 700;
}

.inputRow:nth-of-type(n) {
    margin: 0 0 $gutter-width;
}
