@import '../../variables';

.crumbs {
    display: flex;
    margin: 30px 0px 0px 30px;
    font-size: 11px;
    font-family: roboto;
    font-weight: 600;
    font-style: normal;
    color: #525252;
    text-transform: uppercase;
    white-space: nowrap;

    a:link, a:visited, a:active {
        color: #525252;
        text-decoration: none;

        &:focus {
            background-color: $tab-color;
            color: $tab-text-color;
        }
    
        :global(.__flag-user-is-not-tabbing) &:focus {
            background-color: inherit;
            color: inherit;
        }
    }

    a:hover {
        color: $accent-color;
    }
}

.crumb {
    display: flex;

    svg {
        width: 20px;
        margin: 3px 0px 0px 12px;
    }

    .underline {
        position: relative;
        height: 3px;
        border-radius: 1.5px;

        .underlineFill {
            left: 0px;
            height: 3px;
            width: 0%;
            border-radius: 1.5px;
            transition: width 0.6s ease;
            background-color: $accent-color;
        }

        &:hover {
            .underlineFill {
                width: 100%;
                background-color: $accent-color;
            }
        }
    }
}
