@import '../../../variables';

.secondary-button {
    padding: 10px 10px;
    background-color: transparent;
    color: $accent-color;

    &:hover {
        background-color: #efefef;
    }

    &:focus {
        background-color: #32A1EC;
        color: #000000;
    }

    &:active {
        color: #707070;
    }

    &[disabled] {
        color: lighten(#707070, 30%);
        background-color: transparent;
        cursor: not-allowed;
    }
}
