@import '../variables';

.button {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0px;
    padding: 0px;
    border: none;
    background: none;
    position: relative;

    &:focus {
        background-color: $tab-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: inherit;
    }
}

.menuButtonLine {
    pointer-events: none;
    width: 25px;
    height: 3px;
    background: #fff;
    border-radius: 6px;
    transition: background ease 400ms,
    opacity ease 400ms,
    transform ease 400ms;
    margin-left: auto;
    margin-right: auto;

    &:nth-child(n + 2) {
        margin-top: 4px;
    }

    .menuOpen & {
        background: #fff;

        &:nth-child(1) {
            transform: translateY(7px) rotate(45deg);
        }

        &:nth-child(2) {
            opacity: 0;
        }

        &:nth-child(3) {
            transform: translateY(-7px) rotate(-45deg);
        }
    }
}
