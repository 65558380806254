@import '../variables';

.progress-bar-container {
    display: flex;
    align-items: center;
}

.progress-bar-label, .progress-bar-details {
    color: #999999;
    font-size: 0.9em;
}

.progress-bar {
    width: 100%;
    height: 16px;
    margin: 0px 10px;
    padding: 0px;
    appearance: none;

    @mixin progress-background () {
        background: #fff;
        border: solid 1px #DEDEDE;
        border-radius: 8px;
    }

    @mixin progress-bar () {
        background: $accent-color;
        border-radius: 8px;
    }

    // Chrome uses a psuedo element for the background
    &::-webkit-progress-inner-element {
        @include progress-background();
    }

    // It also has a inner wrapper element with some default styles that get in the way
    &::-webkit-progress-bar {
        background: none;
        border: none;
    }

    // Progress bar psuedo element for Chrome/Webkit
    &::-webkit-progress-value {
        @include progress-bar();
    }

    // Firefox needs styles on the progress element to set the background
    // but Chrome/Webkit breaks when they are applied so we need to special
    // case this here.
    @supports (-moz-appearance: none) {
        @include progress-background();

        &::-moz-progress-bar {
            @include progress-bar();
        }
    }

    // In the same way IE also needs the background styles applied to the root
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        // Older IE will inherit the progress bar colour from currentColor
        color: $accent-color;

        @include progress-background();
    }

    // IE has an element for the bar that can be styled
    &::-ms-fill {
        @include progress-bar();
    }
}
