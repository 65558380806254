@import '../../variables';

.container {
    padding: $gutter-width;
}

.heading {
    display: block;
    margin: 0px;
    padding: 0px;
    color: #7E7E7E;
    font-weight: 500;
    font-size: 1.5em;
}

.body {
    margin-top: $gutter-width;
    overflow: visible;
}
