@import '../../variables';

.circle {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	color: #fff;
	background: $accent-color;
	font-size: 24px;
	font-weight: 700;
}