@import '../../../variables';

.container {
    display: flex;
    flex-wrap: wrap;
    cursor: default;

    @media (min-width: 600px) {
        align-items: center;
    }

    &:focus {
        background-color: $tab-color;
        box-shadow: 0 0 4px 0 $tab-color;
        color: #000000;
    }

    // Swap the elements around while preserving the
    // correct oder in the document for screen readers.
    > dt { order: 1; }
    > dd { order: 0; }

    &.isLink {
        cursor: pointer;

        &:hover {
            .term, .value {
                color: #682f91;
            }
        }
    }
}

.term,
.value {
    $flex-stacked: 0 1 100%;
    $flex-collapse: initial;

    flex: $flex-stacked;
    text-align: center;

    :focus & {
        color: #000000;
    }

    @media (min-width: 600px) {
        flex: $flex-collapse;
        // hack to ensure consistent display in IE11 compared with Chrome
        -ms-flex-preferred-size: auto;
    }

    @media (min-width: 960px) {
        flex: $flex-stacked;
    }

    @media (min-width: 1140px) {
        flex: $flex-collapse;
        // hack to ensure consistent display in IE11 compared with Chrome
        -ms-flex-preferred-size: auto;
    }
}

.term {
    font-size: 0.75em;
    color: #757575;
    margin: 0px 5px 0px 5px;

    @media (min-width: 600px) {
        font-size: 1em;
        margin: 0px 10px 0px 0px;
    }
}

.value {
    font-size: 1.3em;
    color: #555555;
    margin: 0px 5px 0px 5px;

    @media (min-width: 600px) {
        font-size: 1.8em;
        margin: 0px 10px 0px 0px;
    }
}
