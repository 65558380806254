@import '../variables';

.paragraph {
    line-height: 1.8em;
    margin: 0px;
    padding: 0px;

    &:nth-child(n + 2) {
        margin-top: 1em;
    }
}
