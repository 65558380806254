@import "../../../variables";

.detailsCol {
    @media (min-width: 1140px) {
        display: none;
    }
}

.hideSmall {
    composes: hideSmall from '../../../components/tables/data-table/HideColumns.module.scss';
}

.hideMedium {
    composes: hideMedium from '../../../components/tables/data-table/HideColumns.module.scss';
}

.hideLarge {
    composes: hideLarge from '../../../components/tables/data-table/HideColumns.module.scss';
}
