@import '../../variables';

.frame {
    transition: background-color ease $ui-animation-duration * 2;
    color: rgba(255, 255, 255, 0.6);
    background-color: $grey-light;

    &.hasAction {
        cursor: pointer;
    }

    &.hasAction:hover {
        background-color: $accent-color;
        transition-duration: $ui-animation-duration;
    }
}

.container {
    padding: $gutter-width * 1.5 $gutter-width;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.total, .label {
    display: block;
}

.total {
    font-size: 2em;
    margin-right: 0.4em;
}

.label {
    font-weight: 500;
    white-space: normal;
}
