@import "../variables";

.m-input-moment {
    background: #fff;
    box-shadow: 2px 2px 10px 4px rgba(0, 0, 0, 0.2);

    .options {
        button {
            border-color: $accent-color;
            color: $accent-color;

            &.is-active {
                background-color: $accent-color;
            }
        }
    }

    .current-day {
        font-size: 1.3em;
        font-weight: bold;
        color: $accent-color;
    }

    .m-calendar {
        thead td {
            color: $accent-color;
        }

        tbody td:hover {
            background: $accent-color;
            border-color: $accent-color;
        }

        .toolbar {
            .prev-month, .next-month {
                > i {
                    display: none;
                }

                color: #666;
                border-radius: 0px;
                border: none;
                background: none;

                &::before {
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }

            .prev-month::before {
                content: "<";
            }

            .next-month::before {
                content: ">";
            }

            .current-date {
                color: $accent-color;
            }
        }
    }

    .m-time {
        .time {
            background-color: $grey-light;
        }

        .separater {
            color: $grey-light;
        }

        .time-text {
            color: $accent-color;
        }
    }

    .u-slider-time {
        .value {
            background-color: $accent-color;
        }

        .handle {
            &::after {
                border-color: $accent-color;
            }
        }
    }

    .btn-save {
        background-color: $accent-color;
        text-transform: uppercase;
    }
}
