@import '../../variables';

.menu {
    position: absolute;
    padding: 10px;
    background: #fff;
    box-shadow: $container-shadow;
    transition: opacity ease $ui-animation-duration;
    z-index: 100;
}

.hiddenMenu {
    composes: menu;
    opacity: 0;
}

.visibleMenu {
    composes: menu;
    opacity: 1;
}
