@import '../../variables';
@import '~sassdash/index';
@import '~sass-svg/index';

.card {
    position: relative;
    height: 101px;
    width: 100%;
    padding: 0 0 0 30%;
    background: #fff;
}

.dateContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30%;

    background: $grey-dark;
    color: #fff;
}

.date {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    line-height: 20px;
}

.totalContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.name {
    font-weight: 500;
    color: #757575;
}

.total {
    display: flex;
    align-items: center;
}

.svg {
    margin: 3px;
    fill: #919191;
}