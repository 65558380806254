@import '../../variables';

.styleguide-button-section {
    border-collapse: collapse;

    th, td {
        padding: 20px;
    }
}

.styleguide-badge-list {
    > div:nth-child(n + 2) {
        margin-top: $gutter-width;
    }
}
