@import "../../variables";

.navContainer {
    position: relative;
    margin: ($gutter-width / 2) 0 ($gutter-width * 1.5);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 960px) {
        justify-content: center;
    }
}

.rightContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (min-width: 960px) {
        position: absolute;
        right: 0;
    }
}

.filterContainer {
    margin-right: $gutter-width;

    @media (min-width: 1024px) {
        margin-right: ($gutter-width * 2);
    }
}

.clearFilters {
    margin-right: ($gutter-width / 3);
    background: transparent;
    border: none;
    padding: 0;
    color: $accent-color;
}

.leftContainer {
    @media (min-width: 960px) {
        position: absolute;
        left: 0;
    }

    h1 {
        margin-bottom: 0;
        font-size: $heading-font-size;
        font-family: "roboto";
        font-weight: $heading-font-weight;
        color: $heading-font-color;
    }
}

.table {
    width: 100%;
    margin: -20px 0px;
    border-spacing: 0px 30px;
}

.tableHeader {
    white-space: nowrap;

    th {
        font-weight: 500;
        color: #666;
        text-align: left;
        padding: 0px 15px;

        &:first-child {
            width: 10px;
            padding: 0px;
        }

        &.alignRight {
            text-align: right;
            padding-right: 30px;
        }
    }
}

.tableBody {
    transform: translateY(-15px);
}
