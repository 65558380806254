.tableContainer {
    max-width: 980px;
}

.loadingSpinner {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
}
