@import '../../variables';

.contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.errorCard {
    flex: 0 0 100%;
    max-width: 960px;
    margin-top: ($gutter-width * 3);
    background: #fff;
    border-radius: 8px;
    box-shadow: $container-shadow;
}

.cardHeader {
    display: flex;
    align-items: center;
    background: $error-color;
    border-radius: 8px 8px 0 0;
    color: #FFF;
    height: 130px;
    font-size: 1.143em;
}

.cardHeaderInner {
    display: flex;
    align-items: center;
    padding: 0 $gutter-width;
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $gutter-width;
    min-height: 300px;
}

.cardText {
    color: #666;
    margin: 0 0 $gutter-width;
}

.icon {
    width: 22px;
    height: 22px;
    fill: #FFF;
    margin-right: $gutter-width;
}
