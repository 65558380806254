@import '../../variables';
@import '../../../node_modules/sassdash/index';
@import '../../../node_modules/sass-svg/index';

.table-body {
    tr {
        position: relative;
        border-bottom: solid 1px #ededed;
    }

    td {
        padding: 15px 5px;

        &.alignRight {
            text-align: right;
            padding-right: 30px;
        }
    }
}