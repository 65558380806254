@import '../../variables';

.row {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
}

%column {
    flex: 1;
    flex-direction: column;
    display: flex;
}

.column {
    &Left {
        @extend %column;
        align-items: flex-start;
    }

    &Right {
        @extend %column;
        align-items: flex-end;
    }

    &Center {
        @extend %column;
        align-items: center;
    }
}
