@import "../../../variables";

.cardContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    z-index: 10;
}

.detailRow {
    display: flex;
}

.detailCol {
    display: flex;
    flex-direction: column;
    margin: 0 ($gutter-width * 1.5) ($gutter-width * 1.5) 0;

    dt {
        color: #757575;
        margin-bottom: ($gutter-width / 2);
    }

    dd {
        margin: 0;
        font-size: 1.2em;
    }

    .releaseTime {
        display: inline-block;
        margin: 0 ($gutter-width / 2);
        color: #757575;
        font-size: 0.8em;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.backdrop {
    background: rgba($grey-dark, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
}
