@import '../variables';

$shadow-width: 15px;

.container {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 260px;
    background: #fff;
    transition: transform ease $ui-animation-duration;
    z-index: 100;

    &::after {
        display: block;
        content: '';
        position: absolute;
        width: $shadow-width;
        height: 100%;
        top: 0px;
        left: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0));
    }
}

.closedMenuContainer {
    composes: container;
    transform: translateX(-100%) translateX(-$shadow-width);
}

.openMenuContainer {
    composes: container;
    transform: translateX(0px);
}

.header {
    position: relative;
    height: 180px;
    background: $grey-dark;
}

.closeButton {
    display: block;
    background: none;
    border: none;
    margin: 0px;
    padding: 10px;

    > svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: #b0b4b7;
    }
}

.welcomeMessage {
    position: absolute;
    display: block;
    left: 15px;
    bottom: 50px;
    color: #cbced0;
    font-weight: 500;
}

.navContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 10px;
}

.link {
    display: block;
    color: #767676;
    font-weight: 500;
    font-size: 1.2em;
    text-decoration: none;

    &:nth-child(n + 2) {
        margin-top: 20px;
    }
}

.menuItem {
    white-space: nowrap;
    height:45px;
    display: flex;
    font-size: 14px;
}

.menuLink {
    display: inline-block;
    padding: 7.5px;
    text-decoration: none;
    font-weight: 500;

    &:hover {
        .underlineFill {
            width: 100%;
        }
    }

    &:focus {
        background-color: $tab-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: inherit;
    }
}

.underline {
    background: transparent;
    margin-top: 2px;
}

.unbranded {
    .underlineText {
        color: #767676;
    }

    &:focus {
        .underlineText {
            color: $tab-text-color;
        }
    }

    &:hover, &.unbrandedActive {
        .underlineText {
            color: #111;
        }
    }
}

.active {
    .underlineText {
        color: #111;
    }

    .underlineFill {
        width: 100%;
    }
}
