@import '../../variables';

.badge {
    display: flex;
    align-items: center;
}

.badge-value {
    padding: 2px 12px;
    min-width: 50px;
    text-align: center;
    border-radius: 1em;
    color: #fff;

    .badge-accent & {
        background: $accent-color;
    }

    .badge-dark & {
        background: $grey-light;
    }
}

.badge-label {
    color: #9a9a9a;
    font-size: 1.2em;
    margin-left: 10px;
}
