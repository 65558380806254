@import '../helpers';

.containerStyles {
  margin: 0;
}

.cardSideMenu {
  background: #454F54;
  width: 12.5%;
  border-radius: 6px 0 0 6px;
}

.linkContainer {
  display: flex;
  flex-direction: column;
}

.menuItem {
  white-space: nowrap;
  height:45px;
}

.menuLink {
  color: #cbced0;
  margin-left: 20px;
  margin-top: 30px;
  display: inline-flex;
  font-weight: 400;
  font-size: 1.2em;
  text-decoration: none;
  cursor: pointer;

  &:nth-child(n + 2) {
    margin-top: 10px;
  }

  &.active {
    color: #ffffff;
    font-weight: 500;
  }

  &:hover {
    .underlineFill {
      width: 100%;
    }
  }

  &:focus {
    background-color: $tab-color;
  }

  :global(.__flag-user-is-not-tabbing) &:focus {
    background-color: inherit;
  }
}

.underline {
  background: transparent;
  margin-top: 2px;
}

.unbranded {
  .underlineText {
    color: #cbced0;
  }

  &:hover, &.unbrandedActive {
    .underlineText {
      color: #fff;
    }
  }

  &:focus {
    .underlineText {
        color: $tab-text-color;
    }
  }

  :global(.__flag-user-is-not-tabbing) &:focus {
    .underlineText {
        color: #fff;
    }
  }
}

.active {
  .underlineText {
    color: #fff;
  }
  .underlineFill {
    width: 100%;
  }
}
