@import '../../../variables';
@import '../../../../node_modules/sassdash/index';
@import '../../../../node_modules/sass-svg/index';

.dataTableSearchInput {
  margin: 0 0;
  padding: 8px 30px 8px 12px;
  font-size: 1em;
  border-radius: 6px;

  background-color: #FFFFFF;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  background-size: 16px 16px;

  @include svg((viewBox: 0 0 16 16, "xml:space": preserve)) {
    @include svg('path', (
      fill: #8F8F8F,
      opacity: 0.7,
      d: "M11.688,9.75a6.322,6.322,0,1,0-1.9,1.925l.287-.2L14.6 16l1.4-1.429-4.521-4.525zm-1.8-6.95a5.009,5.009,0,1,1-3.542-1.467A4.977 4.977,0,0,1,9.892,2.8z"
    ));
  }

  &::placeholder {
    color: $input-placeholder-color;
  }

  &:focus {
    border: $tab-border;
  }

  :global(.__flag-user-is-not-tabbing) &:focus {
    border: $input-border-active;
  }
}

.searchContainer {
  padding: 0 25px;
  margin: auto;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  padding: 0 3%;
  margin-bottom: 50px;

  @media (max-width: 1080px) {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
  margin: auto;

  @media (max-width: 1080px) {
    margin-bottom: 25px;
  }
}

.menu {
  width: 350px;
  max-height: 200px;
  overflow-y: scroll;
  position: fixed;
  scrollbar-color: #682f91 #eee;
  scrollbar-width: thick;

  @media (max-width: 1080px) {
    margin-bottom: 25px;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #682f91;
    border-radius: 8px;
    border: 3px solid #eee;
  }
}

.columnContainer {
  display: flex;
  justify-content: space-between;
}

.column1, .column2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column1 {
  width: 50%;
  color: #682f91;
  font-weight: 500;
  text-decoration: none;
}

.column2 {
  width: 50%;
  text-align: right;
}
