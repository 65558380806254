@import '../../../variables';

.primary-button {
    background-color: $accent-color;
    color: #fff;

    &:hover {
        box-shadow: $raised-shadow;
    }

    &:active {
        box-shadow: none;
    }

    &[disabled] {
        background: #efefef;
        color: #bbb;
        box-shadow: none;
        cursor: not-allowed;
    }
}
