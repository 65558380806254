@import '../../../helpers';
@import '../../../variables';

.card {
  display: flex;
  background: white;
  min-height: 350px;
  max-width: 1440px;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 30px 30px 0;

  @media (min-width: 1500px) {
    margin: 30px auto 0;
  }
}

.shadow {
  @include shadow-elevation(1);
}

.cardFooter {
  height: 50px;
}

.bodyContainer {
  display: flex;
  margin-bottom: 25px;
}

.editButton {
  text-transform: uppercase;
  margin: 2px 0 0 0;
  background: none;
  border: none;
  height: 28px;
  color: #652D90;
  font-weight: 500;
  font-family: roboto-condensed;

  &:focus {
    background-color: $tab-color;
    color: $tab-text-color;
  }

  :global(.__flag-user-is-not-tabbing) &:focus {
    background-color: none;
    background: none;
    color: #652D90;
  }
}

.cardItem {
  float: left;
  clear: left;
  width: 150px;
  font-weight: bold;
  color: $grey-light;
}

.cardValue {
  margin: 0 0 0 110px;
  padding: 0 0 0.5em 0;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

