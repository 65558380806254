@import '../../../variables';

.textLink {
    color: $accent-color;
    text-decoration: none;
    font-weight: 700;

    &:focus {
        background-color: $tab-color;
        color: $tab-text-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: none;
        background: none;
        color: $accent-color;
    }
}
