@import '../../../variables';

.stepList {
    list-style: none;
    margin: 0;
    padding: 0;
}

.step {
    margin: 0 0 ($gutter-width / 2);

    strong {
        display: inline-block;
        margin: 0 ($gutter-width / 3) 0 0;
        font-weight: 700;
    }
}
