@import '../../variables';

.table-header {
    th {
        text-align: left;
        color: #757575;
        padding: 15px 5px;

        &.alignRight {
            text-align: right;
            padding-right: 30px;
        }
    }
}
