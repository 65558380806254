@import '../../../variables';

.error {
    border: 1px solid $error-color;

    &:focus {
        border-color: $error-color;
        box-shadow: 0 0 4px 0 $error-color;
    }
}

.upload {
    margin-top: ($gutter-width * 3);
}

.title {
    color: #666;
    font-weight: 500;
    margin: 0 0 20px;
}

.button {
    text-transform: uppercase;
}

.secondaryButton {
    text-transform: uppercase;
    margin-left: $gutter-width;
}

.formSection {
    margin-bottom: $gutter-width;
}

.fileRow:nth-child(n) {
    margin: 0 0 6px;
}

.payrollsRow:nth-child(n),
.notificationRow:nth-child(n) {
    margin: 0;
}

.payrollInput {
    &:nth-child(n + 2) {
        margin-top: 6px;
    }
}

.visibilitySectionHeader {
    white-space: nowrap;

    * {
        display: inline-block;
    }
}

.employeeCountLabel {
    color: #757575;
    line-height: 20px;
    font-size: 12px;
}

.employeeCount {
    height: 20px;
    background-color: #555555;
    color: #FFF;
    border-radius: 10px;
    padding: 0 10px;
    margin: 0 5px 0 20px;
}