@import '../../../variables';

.payrollSection,
.contactSection,
.accountSection {
    flex: 0 0 100%;
    min-width: 100%;
}

.container {
    display: flex;
    flex-flow: row wrap;

    > div {
        display: flex;
        flex-basis: 100%;
        border-bottom: 1px solid #ededed;

        &:last-of-type {
            border-bottom: none;
        }

        > dt {
            flex-basis: 30%;
            color: #757575;
            font-size: 16px;
            font-family: roboto;
            font-weight: 600;
            padding: 23px;
        }

        > dd {
            flex-basis: 70%;
            flex-grow: 1;
            color: #333;
            padding: 23px;
        }
    }
}
