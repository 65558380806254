@import '../../../variables';

.templatePreviewCard {
    max-width: 800px;
    border-radius: 6px;
    box-shadow: $container-shadow;
    padding: $gutter-width;
    margin-bottom: $gutter-width;

    .whiteSpaceWrapper {
        white-space: pre-wrap;
    }
}

.secondaryButton {
    margin-left: $gutter-width;
}
