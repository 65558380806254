@import '../../../variables';

.sortButton {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: #757575;
    padding: 0;

    &:focus {
        background-color: $tab-color;
        color: #000000;
    }
}

.sortIcon {
    margin: 0 6px 0 0;
    fill: #757575;
    transition: transform ease $ui-animation-duration;
    transform-origin: center;
    transform: rotate(0deg);

    :focus & {
        fill: #000000;
    }
}

.arrowUp {
    transform: rotate(-180deg);
    transition: transform ease $ui-animation-duration;
}
