@import '../../../variables';

.container {
  margin-top: 30px;
  width: 100%;
}

.title {
  margin-bottom: 10px;
  float: left;
  clear: left;
  font-weight: 500;
  color: $grey-light;
}

.content {
  float: left;
  clear: left;
  font-weight: 400;
  font-style: italic;
  color: #878787;
}

.selectInput {
  margin: 0;
}

.inputBox {
  min-width: 200px;
  max-width: 400px;
}

.buttonContainer {
  margin: 30px 0 0;

  @media (min-width: 960px) {
    margin: (30px * 1.5) 0 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.bodyContainer {
  display: flex;
  margin-bottom: 25px;
}

.editButton {
  text-transform: uppercase;
  margin: 2px 0 0 0;
  background: none;
  border: none;
  height: 28px;
  color: #652D90;
  font-weight: 500;
  font-family: roboto-condensed;

  &:focus {
    background-color: $tab-color;
    color: $tab-text-color;
  }

  :global(.__flag-user-is-not-tabbing) &:focus {
    background-color: none;
    background: none;
    color: #652D90;
  }
}

.secondaryButton {
  margin-left: $gutter-width;
}
