@import '../../../helpers';

.payrollLink {
  color: #652D90;
  font-weight: 500;
  font-size: 1.2em;
  text-decoration: none;
  cursor: pointer;

  &:nth-child(n + 2) {
    margin-top: 10px;
  }

  &:focus {
    background-color: $tab-color;
    color: $tab-text-color;
  }

  :global(.__flag-user-is-not-tabbing) &:focus {
    background-color: none;
    background: none;
    color: #652D90;
  }
}

.card {
  display: flex;
  background: white;
  min-height: 350px;
  max-width: 1440px;
  box-sizing: border-box;
  border-radius: 6px;
  margin: 30px 30px 0;

  @media (min-width: 1500px) {
    margin: 30px auto 0;
  }
}

.shadow {
  @include shadow-elevation(1);
}

.tableTitle {
  margin: 0;
}

.cardFooter {
  height: 50px;
}

.loadingSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
