@import '../../../variables';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:not(:first-child) {
        margin-top: $gutter-width / 2;

        @media (min-width: 600px) {
            margin-top: $gutter-width;
        }

        @media (min-width: 960px) {
            margin-top: $gutter-width * 2;
        }
    }
}

.heading {
    // Needed for IE, default value may differ accross browsers hence need for explict declaration
    // https://github.com/philipwalton/flexbugs#flexbug-6
    flex: 0 1 auto;
    margin: 0px;
    padding: 0px;
    color: $heading-color;
    font-weight: $heading-font-weight;
    font-size: 18px;
}

.content {
    flex: 1 1 100%;
    // hack to ensure consistent display in IE11 compared with Chrome
    -ms-flex-preferred-size: auto;
    margin-top: 10px;

    @media (min-width: 600px) {
        margin-top: 20px;
    }
}
