@import '../../../variables';

.filterContainer {
    display: flex;
    justify-content: flex-end;
    margin-right: $gutter-width;
}

.clearFilters {
    margin-right: ($gutter-width / 3);
    background: transparent;
    border: none;
    padding: 0;
    color: $accent-color;
}
