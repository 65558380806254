.documentPreviewCanvas {
    position: relative;

    &.responsive {
        height: calc(100% - 71px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    canvas {
        max-width: 100%;
        max-height: 100%;
    }
}

.canvasLink {
    display: block;
}
