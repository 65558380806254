.documentPreviewCanvas {
    &:hover .pageButtons {
        opacity: 1;
    }
}

.pageButtons {
    opacity: 0;
    transition: .5s ease opacity;
}
