@import './variables';
@import './scrollbar';

html {
    @include theme-scrollbar;
}

body {
    background: $background-color;
    overflow-x: hidden; // Hides the drawer component where used
    overflow-y: scroll;
}

body * {
    outline: none;
}

.skipLink {
    background: #32A1EC;
    color: #000000;
    padding: 15px;
    position: absolute;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s;
    z-index: 102;
}

.skipLink:focus {
    left: 8%;
    top: 3%;
    opacity: 1;
}
