@import '../variables';

.button {
    composes: button from './MenuButton.module.scss';
    width: 40px;
    height: 40px;

    &:focus {
        background-color: $tab-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: inherit;
    }
}

.icon {
    margin: auto;
    display: block;
    width: 25px;
    height: 25px;
    fill: #C7C7C7;

    &:hover {
        fill: #FFFFFF;
    }
}
