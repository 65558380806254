@import '../../../variables';

.container {
    overflow: auto;
}

.avatar {
    float: left;

    & + & {
        margin-left: 10px;
    }
}
