@import '../variables';

.indicatorIcon {
    display: inline-block;
    margin-left: 10px;
    border: 1px solid #AAA;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    padding: 1px;

    &.warn .indicatorIconInner { background-color: $warn-color; }
    &.success .indicatorIconInner { background-color: $success-color; }
    &.error .indicatorIconInner { background-color: $error-color; }
    &.info .indicatorIconInner { background-color: $info-color; }
}

.indicatorIconInner {
    min-width: 100%;
    min-height: 100%;
    border-radius: 50%;
}
