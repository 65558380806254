@import '../../variables';

.errorListAlert {
    max-width: (800 + ($gutter-width * 0.75));
    margin-top: 25px;
}

.errorList {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        margin-bottom: $gutter-width / 3;

        &:last-of-type { margin-bottom: 0; }
    }
}
