@import '../../../variables';

.empAvatar {
    width: 36px;
    height: 36px;
    margin: 0 14px 0 0;
}

.backButton {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 0px;
    background: none;
    border: none;

    @media (min-width: 960px) {
        left: 18px;
    }
}

.backButtonArrow {
    display: block;
    width: 24px;
    height: 24px;
    fill: #858585;
}
