@import '../../../variables';

.error {
    border: 1px solid $error-color;

    &:focus {
        border-color: $error-color;
        box-shadow: 0 0 4px 0 $error-color;
    }
}

.inputRow:nth-child(n) {
    margin-top: 60px;
}

.loadingSpinner {
    margin-top: 30px;
}

.fileTypesInput {
    margin-top: 10px !important;
}

.secondaryButton {
    margin-left: $gutter-width;
}
