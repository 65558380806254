@import '../../../variables';

.textArea {
    box-sizing: border-box;
    width: 750px;
    max-width: 100%;
    min-height: 450px;
    padding: ($gutter-width / 2);
    margin: 0;
    resize: vertical;
    white-space: pre-wrap;
    line-height: 1.875;
    font-family: $base-font-family;
}

.textAreaRow {
    margin: $gutter-width 0 ($gutter-width * 2);
}

.error {
    border: 1px solid $error-color;

    &:focus {
        border-color: $error-color;
        box-shadow: 0 0 4px 0 $error-color;
    }
}

.publishSection {
    max-width: 600px;
    margin-bottom: $gutter-width;

    &:last-of-type {
        margin-bottom: ($gutter-width * 2);
    }
}

.sectionTitle {
    color: #666;
    font-size: 1.214em;
    font-weight: 500;
}

.dateRow:nth-child(n) {
    display: flex;
    margin-top: ($gutter-width) / 2;
    flex-wrap: wrap;

    > span {
        flex: 0 0 100%;
    }
}

.datePicker {
    max-width: 150px;
    margin-right: $gutter-width;
}

.payrollRow:nth-child(n) {
    margin-top: ($gutter-width / 3);
}

.message {
    white-space: pre-wrap;
}

.secondaryButton {
    margin-left: $gutter-width;
}

