@import '../variables';
@import '../helpers';

.bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-bar-height;
    padding: 0px 20px;
    background: #454F54;
    color: #FAFAFA;
}

.barLoaded {
    animation-delay: 800ms;
    transform: translateY(-100%);

    @include keyframe-animation((
        ( delay: 0ms, styles: ( transform: translateY(-100%) ) ),
        ( delay: 300ms, styles: ( transform: translateY(0%) ) ),
    ));
}

.options {
    display: flex;
    align-items: center;
}

.globalOptions {
    composes: options;
}

.accountOptions {
    composes: options;
}

.optionContainer, .hamburgerContainer {
    &:not(:first-child) {
        margin-left: 10px;
    }
}

.hamburgerContainer {
    z-index: 101;
}

.buttonMenu {
    min-width: auto;
    border-radius: 6px;
    box-shadow: -3px 1px 20px rgba($color: #000000, $alpha: 0.51);
    transform: translateY(-10px) translateX(10px);
    padding: 10px 16px;
}

.action, .adminAction, .header {
    font-size: 16px;
}

.action, .adminAction {
    width: 100%;
    max-width: 100%;
    text-align: left;
    cursor: pointer;
    text-transform: capitalize;
    color: #333;
    padding: 6px 0;
    font-weight: 500;

    &:last-of-type {
        margin-bottom: 0;
    }

    &:hover {
        color: $accent-color;
    }
}

.header {
    font-weight: 600;
}

.optionsLoaded {
    animation-delay: 2000ms;
    opacity: 0;

    @include keyframe-animation((
        ( delay: 0ms, styles: ( opacity: 0 ) ),
        ( delay: 1ms, styles: ( opacity: 1 ) ),
    ));
}

.topLinksContainer {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    color: black;

    li {
        margin: 0 0 0 50px;
        font-size: 1.214em;
    }
}

.topLink {
    text-decoration: none;
    transition: opacity 0.5s ease;
    color: #fff;
    
    &:hover {
        opacity: 1;
    }

    &:focus {
        background-color: $tab-color;
        color: $tab-text-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: inherit;
        color: #fff;
    }
}

.activeLink {
    opacity: 1;
}