@import "../../variables";

.cell {
    display: flex;
}

.wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.editButton {
    margin-left: 10px;
    padding: 2px;
}

.editMask {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #eee;
    border: solid 1px #ccc;
    border-radius: 2px;
}

.closeEditButton {
    position: absolute;
    right: 0px;
    top: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    transform: translate(100%, -50%) translateX(10px);
    border: none;
    background: none;
}

.closeIcon {
    display: block;
    width: 20px;
    height: 20px;
    fill: #ccc;
}

.calendarContainer {
    position: absolute;
    z-index: 100;
}
