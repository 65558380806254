@import '../../../../variables';
@import '../../../../../node_modules/sassdash/index';
@import '../../../../../node_modules/sass-svg/index';

.filterButton {
    padding: 6px ($gutter-width * 1.5) 6px 8px;
    border: $input-border-active;
    border-radius: 4px;
    color: $input-filled-color;

    background-color: transparent;
    background-position: 100% center;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    @include svg((viewBox: 0 0 24 24, "xml:space": preserve)) {
        @include svg('path', (
            fill: #666,
            opacity: 0.41,
            d: "M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"
        ));
    }

    &:focus {
        border: $tab-border;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        border: $input-border-active;
    }
}

.filterButton + .filterButton {
    margin-left: 10px;
}

.filterButtonMenu {
    padding: ($gutter-width / 2);
    min-width: 275px;
}

.clearFilters {
    margin-top: ($gutter-width / 2);
    background: transparent;
    border: none;
    padding: 0;
    color: $accent-color;
}
