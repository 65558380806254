@import '../variables';

.button {
    composes: button from './MenuButton.module.scss';
}

.icon {
    display: block;
    width: 100%;
    height: 100%;
    fill: #a7abae;
}
