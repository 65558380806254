.messageLink {
    position: relative;
    cursor: pointer;
    box-sizing: inherit;
    text-decoration: none;
    color: #682f91;
    font-weight: 700;
}

.attachmentIcon {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 100%;
    top: 50%;
    transform: translate(10px, -50%);
}