@import '../../../variables';

.labelExpander {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    margin: -10px;
}

.label {
    flex: 0 0 auto;
    margin: 5px;
}

.hidden {
    display: none;
}

.showHideButton {
    border: none;
    background: transparent;
    font-weight: 700;
    color: #707070;
    padding: 2px 5px;
    margin: 5px;
    text-transform: uppercase;

    &:focus {
        background-color: $tab-color;
        color: $tab-text-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: none;
        color: #707070;
    }
}
