@import "src/variables";

.externalLink {
    display: inline-flex;
    font-family: roboto-condensed;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 75%;
    padding: 10px 30px 10px 0px;
}

.drawer {
    z-index: 101;
    width: auto;
    max-width: 100%;
    min-width: 300px;
    // transition out
    transition: 0.5s ease-in;

    &.visible {
        // transition in
        transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1);
    }

    > button {
        top: 15px;
        left: 25px;
    }

    > div {
        padding: 90px 75px 25px 75px;

        > :first-child {
            margin-bottom: 55px;
        }

        .buttonContainer {
            top: 25px;
        }
    }

    .icon {
        color: #858585;
        width: 20px;
        height: 20px;

        &:hover {
            cursor: pointer;
        }

        &.back {
            width: 25px;
            height: 25px;
        }
    }

    .pdfContainer {
        width: min-content;
        max-width: 100%;
        max-height: calc(100vh - 300px);
        margin: 0px auto;
        border: 1px solid #ccc;
        border-radius: 6px;
        box-sizing: border-box;
        overflow: auto;

        canvas {
            margin: 12.5px;
        }

        &.hidden {
            visibility: hidden;
            overflow: hidden;
            height: 0px;
        }
    }

    &.thin {
        > div {
            padding: 90px 25px 25px 25px;

            > :first-child {
                margin-bottom: 40px;
            }
    
            > :last-child {
                margin-top: 40px;
            }
    
            button {
                > span {
                    font-size: 14px;
                }
            }
    
            .buttonContainer {
                position: absolute;
                top: 12px;
                right: 25px;
            }
        }
    }
}

.heading {
    font-size: 18px
}

.container {
    margin-top: -25px;
}
