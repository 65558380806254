@import '../../../variables';
@import '../../../scrollbar';
@import '~sassdash/index';
@import '~sass-svg/index';

.buttons {
    margin-top: ($gutter-width * 2);
}

.button {
    text-transform: uppercase;
}

.secondaryButton {
    text-transform: uppercase;
    margin-left: $gutter-width;
}

.dropDownButton {
    padding: 6px 0px 6px 0px;
    border: 0px;
    color: #666;
    width: 350px;
    text-align: left;
    background-color: transparent;

    &:hover {
        color: $accent-color
    }
}

.dropDownList {
    list-style-type: none;
    padding-left: 0;
    overflow-y: scroll;
    @include theme-scrollbar;
    max-height: 100px;
}

.textInput, .textArea {
    box-sizing: border-box;
    width: 800px;
    max-width: 100%;
    padding: ($gutter-width / 2);
    margin: 0;
    line-height: 1.875;
    font-family: $base-font-family;
}

.textInput {
    height: 37.5px;
}

.textArea {
    height: 225px;
    resize: none;
    white-space: pre-wrap;
}

.textAreaRow {
    margin: $gutter-width 0 $gutter-width;
}

.fileList {
    overflow-y: auto;
    width: 360px;
    min-height: 39px;
    max-height: 117px;
    margin-bottom: 10px;

    > div {
        margin: 0;
    }
}

.error {
    border: 1px solid $error-color;

    &:focus {
        border-color: $error-color;
        box-shadow: 0 0 4px 0 $error-color;
    }
}
