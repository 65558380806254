@import "../../../variables";
@import '../../../scrollbar';
@import '../../../../node_modules/sassdash/index';
@import '../../../../node_modules/sass-svg/index';

.importStatus {
    display: inline-block;
    margin-left: 20px;
}

.submitRow:last-of-type {
    margin-top: $gutter-width;

    @media (min-width: 960px) {
        margin-top: ($gutter-width * 1.5);
    }
}

.dropDownButton {
    padding: 6px 0px 6px 0px;
    border: 0px;
    color: #666;
    width: 350px;
    text-align: left;
    background-color: transparent;

    &:hover {
        color: $accent-color
    }
}

.dropDownList {
    list-style-type: none;
    padding-left: 0;
    overflow-y: scroll;
    @include theme-scrollbar;
    max-height: 100px;
}

.secondaryButton {
    margin-left: $gutter-width;
}
