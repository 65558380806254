@import '../../variables';

.employeeProfileTab {
    display: flex;
    flex-direction: row;
    margin: 30px 30px;
}

.personalDetailsSection {
    flex: 1 1 100%;
    margin-right: $gutter-width;
}

.latestPayslipSection,
.latestDocumentsSection {
    flex: 0 0 calc(50% - #{$gutter-width / 2});
    max-height: 100%;
}

.employeeProfileCardContainer {
    display: flex;
    flex-direction: column;
    flex: 50%;
}
