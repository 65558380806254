@import '../../variables';

.card-row-container {
    display: flex;

    @for $cols from 1 through 12 {
        &[data-grid-width="#{$cols}"] {
            grid-column: span #{$cols};

            // IE layout hack, see comment in CardRow.scss
            @media all and (-ms-high-contrast: none) {
                flex: 1;

                &:nth-child(n + 2) {
                    border-left: solid $gutter-width / 2 transparent;
                }

                &:not(:last-child) {
                    border-right: solid $gutter-width / 2 transparent;
                }
            }
        }
    }
}
