@import '../../../variables';

.default-button {
    margin: 0px;
    padding: 10px 50px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;

    transition: background-color ease $ui-animation-duration,
                box-shadow ease $ui-animation-duration,
                color ease $ui-animation-duration;

    > span {
        position: relative;
    }

    & + & {
        margin-left: $gutter-width;
    }
}
