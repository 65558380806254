@import '../../../variables';

.payslipExtras {
    display: flex;
    flex-wrap: wrap;
    width: 40%;
    margin: 0;
    color: #7E7E7E;
}

.extraName {
    margin: 0;
    padding: 0 $gutter-width 0 0;
    font-weight: 500;
    flex: 1 0 175px;
}

.extraValue {
    margin: 0;
    width: 100%;
    flex: 0 1 calc(100% - 175px);
}

.extraName,
.extraValue {
    margin-bottom: ($gutter-width / 2);
}
