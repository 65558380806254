@import '../../../variables';

.upload {
    margin-top: ($gutter-width * 2);
}

.error {
    border: $input-border-error;

    &:focus {
        border-color: $error-color;
        box-shadow: 0 0 4px 0 $error-color;
    }
}

.secondaryButton {
    margin-left: $gutter-width;
}
