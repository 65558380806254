@import "../../../variables";
@import "../../../../node_modules/sassdash/index";
@import "../../../../node_modules/sass-svg/index";

.row {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);

    > td {
        padding: 40px 15px;
        background-color: #fff;

        &:first-child {
            border-radius: 4px 0px 0px 4px;
        }

        &:last-child {
            border-radius: 0px 4px 4px 0px;
        }

        &.paydate {
            width: 10px;
            background-color: $grey-dark;
            border-radius: 4px 0px 0px 4px;
            color: #fff;
            font-size: 17px;
            text-align: center;
            white-space: nowrap;
        }

        &.alignRight {
            text-align: right;
            padding-right: 30px;
        }
    }
}

.releaseTime {
    color: #757575;
    margin-left: 0.5em;
    margin-right: 1em;
    font-size: 13.6px;
}

.calendarWrapper {
    position: absolute;
    z-index: 10;
}

.appendedCompanyName {
    display: block;
    color: #757575;
    font-size: 0.9em;

    @media (min-width: 960px) {
        display: none;
    }
}

.releaseColumn {
    width: 300px;
}

.largeColumn {
    width: 225px;
}

.mediumColumn {
    width: 180px;
}

.smallColumn {
    width: 125px;
}

.actionButton {
    background: none;
    border: none;
    padding: 0;
    font-size: 0.9em;
    color: $accent-color;
    text-transform: uppercase;
}
