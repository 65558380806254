@import "src/variables";

.card {
  margin-top: 50px;
}

.cardText {
  color: #757575;
  margin: 0 0 $gutter-width;
  font-size: 0.875em;
  font-family: 'roboto-condensed';

  @media (min-width: 600px) {
    font-size: 1em;
  }
}

.secondaryContent {
  width: 100%;

  @media (min-width: 600px) {
    width: 40%;
  }
}

.tertiaryContent {
  margin-top: 4%;
}

.tertText, .tertTextHidden {
  opacity: 100%;
  text-align: left;
  font-size: 13px;
  font-weight: 300;
  color: #757575;
}

.tertTextHidden {
  opacity: 0;
  height: 0;
}

.skipButton {
  float: right;
  margin-top: 10px;
}

.arrowOpen, .arrowClosed {
  position: relative;
  top: 7px;
  right: 0px;
  fill: #818181;
  height: 25px;
  width: 25px;
  transition: transform ease $ui-animation-duration;
}

.arrowOpen {
  transform: rotate(90deg);
}

.footerButtons {
  margin-top: 20px;
}
