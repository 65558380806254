@import '../../../variables';

.passwordField {
    width: 325px;
    max-width: 100%;
    margin: 0 ($gutter-width * 0.75) 0 0;
}

.passwordStrengthCheck {
    color: #a9a9a9;
}
