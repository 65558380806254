$container-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
$raised-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.16);
$tooltip-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.27);
$focus-shadow: 0px 0px 4px 0px #1592E6;

$gutter-width: 30px;

$grey-dark: #454F54;
$grey-light: #555E63;

$background-color: #eee;
$accent-color: #682f91;

$warn-color: #FF8B07;
$success-color: #28A745;
$error-color: #DC3545;
$info-color: #17A2B8;

$tab-color: #32A1EC;
$tab-text-color: #000000;
$tab-border: 2px solid $tab-color;

$body-color: #333;
$heading-color: #555;

$ui-animation-duration: 300ms;

$header-bar-height: 64px;

// Typography
$base-font-color:  $body-color;
$base-font-family: roboto, 'Calibri', 'Helvetica', sans-serif;
$base-font-size:   14px;
$base-line-height: 1.5;

// Headings
$heading-font-color:  $heading-color;
$heading-font-family: inherit;
$heading-line-height: 1.333;
$heading-font-weight: 600;
$heading-font-size: 18px;

//Forms
$input-border-active: 2px solid #929292;
$input-border-disabled: 2px solid #EFEFEF;
$input-border-hover: 2px solid #1592E6;
$input-border-error: 2px solid $error-color;
$input-background-color-disabled: #f3f3f3;
$input-placeholder-color: #757575;
$input-filled-color: #666;
