@import '../../../variables';

.payrollLabel {
    display: inline-block;
    background-color: #ebebeb;
    color: #666;
    font-size: 0.786em;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 6px;
    padding: 4px 8px;
    margin: -5px;
}
