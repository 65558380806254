@import '../../variables';

.inlineEditContainer {
    position: relative;

    &.inDataTable {
        margin: 0 ($gutter-width * 1.5) 0 10px;
    }
}

.editableField {
    position: relative;
    display: inline-block;
    min-width: 70px;
    max-width: 100%;

    &.editing,
    &:hover,
    &.emphasizeEmpty {
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            top: -10px;
            right: -10px;
            bottom: -10px;
            left: -10px;
            border: $input-border-active;
            border-radius: 4px;
        }
    }

    &.emphasizeEmpty,
    &.inDataTable {
        min-width: 75px;

        &::after {
            top: -17px;
            bottom: -15px;
        }
    }

    &.emphasizeEmpty {
        &::after {
            background-color: #EEE;
        }
    }

    &.editing {
        opacity: 0;
    }

    &.error:after {
        border-color: $error-color;
    }
}

.inlineEditInput {
    background: #fff;
    border: $input-border-hover;
    border-radius: 4px;
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    padding: 8px;
    font-family: inherit;
    font-size: 14px;

    &.inDataTable {
        top: -17px;
        height: calc(100% + 32px);
    }
}

.buttonContainer {
    display: flex;
    align-items: center;
    position: absolute;
    left: calc(100% + 15px);
    top: 0;
    width: 39px;
    height: 21px;
}

.saveButton,
.cancelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $input-placeholder-color;
    background: #fff;
    border: none;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    padding: 0;
    transition: ease .5s background;
}

.saveButton {
    margin-right: 5px;
}

.saveButtonSVG {
    fill: $input-placeholder-color;

    &:hover {
        fill: $success-color
    }
}

.cancelButton {
    fill: $input-placeholder-color;

    &:hover {
        fill: $error-color
    }
}

.calendarWrapper {
    position: absolute;
    z-index: 1;
}

.validationError {
    position: absolute;
    left: -10px;
    right: -10px;
    top: calc(100% + 9px);
    background-color: $error-color;
    color: #FFF;
    border-radius: 0 0 2px 2px;
    padding: 3px 10px;
}
