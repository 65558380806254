@import './variables';

@function opposite-direction($direction) {
    $map: (
        "top": "bottom",
        "right": "left",
        "bottom": "top",
        "left": "right",
    );

    @if not map-has-key($map, $direction) {
        @error "No opposite direction defined for '#{$direction}'";
    } @else {
        @return map-get($map, $direction);
    }
}

@mixin border-triangle($direction, $color, $width, $height) {
    width: 0px;
    height: 0px;

    $main-border: solid $height $color;
    $side-borders: solid ($width / 2) transparent;

    border-#{opposite-direction($direction)}: $main-border;

    @if $direction == top or $direction == bottom {
        border-left: $side-borders;
        border-right: $side-borders;
    } @else if $direction == right or $direction == left {
        border-top: $side-borders;
        border-bottom: $side-borders;
    }
}

@mixin keyframe-animation($steps, $name: unique-id()) {
    $total-time: 0ms;

    @each $step in $steps {
        $total-time: $total-time + map-get($step, delay);
    }

    animation-duration: $total-time;
    animation-name: $name;
    animation-fill-mode: forwards;

    @keyframes #{$name} {
        $offset: 0ms;

        @each $step in $steps {
            $offset: $offset + map-get($step, delay);
            $percentage: ($offset / $total-time) * 100%;

            #{$percentage} {
                @each $property, $value in map-get($step, styles) {
                    #{$property}: #{$value};
                }
            }
        }
    }
}

@mixin shadow-elevation($elevation: 1, $card-fold: false) {
    $elevation-shadow-one: #ddd;
    $elevation-shadow-two: #595959;
    $elevation-shadow-three: #666;

    $icon-elevation-shadow: #999;

    $elevation-blur-one: 6px;
    $elevation-blur-two: 15px;
    $elevation-blur-three: 40px;

    $shadow: -3px 1px $elevation-blur-one $elevation-shadow-one;

    @if ($elevation == 2) {
        transform: scale(1.03);
        $shadow: -3px 1px $elevation-blur-two $elevation-shadow-two;
    } @else if ($elevation == 3) {
        $shadow: -3px 1px $elevation-blur-three $elevation-shadow-three;
    }

    > div {
        // The fold itself - this is hidden behind all content so no custom shadows needed
        &::after {
            box-shadow: $shadow;
        }
    }

    @if ($card-fold) {
        $before-shadow: $shadow;

        @if ($elevation == 1) {
            $shadow: -3px 2px $elevation-blur-one -2px $elevation-shadow-one;
        } @else if ($elevation == 2) {
            $before-shadow: -6px -1px $elevation-blur-two -3px $elevation-shadow-two;
        } @else if ($elevation == 3) {
            $shadow:
                    -20px 20px $elevation-blur-three -20px $elevation-shadow-three,
                    20px 20px $elevation-blur-three -24px $elevation-shadow-three;
            $before-shadow:
                    -12px -10px $elevation-blur-three -14px $elevation-shadow-three,
                    -13px 48px $elevation-blur-three -14px $elevation-shadow-three;
        }

        > div {
            // Pseudo element - custom shadow needed
            &::before {
                box-shadow: $before-shadow;
            }
        }
    }

    box-shadow: $shadow;
}
