@import '../../../variables';

.actionsContainer {
    display: flex;
}

.advancedSearch {
    margin-left: 50px;
    text-transform: uppercase;
    font-weight: 500;
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    background: #ffffff;
}

.dataTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;
}

.dataTableLoading {
    justify-content: center;
    padding: $gutter-width;
}

.buttonLink {
    display: inline-block;
    padding: 0;
    background: transparent;
    border: none;
    font-weight: 700;
    color: $accent-color;

    &:focus {
        background-color: $tab-color;
        color: $tab-text-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: none;
        background: none;
        color: $accent-color;
    }
}

.icon {
    color: #858585;
    width: 20px;
    height: 20px;
    transform: rotate(90deg);

    &:hover {
        cursor: pointer;
    }
}
