@import '../../../variables';
@import '../../../../node_modules/sassdash/index';
@import '../../../../node_modules/sass-svg/index';

.dataTableSearchInput {
    margin: 0 0;
    padding: 8px 30px 8px 12px;
    font-size: 1em;
    border-radius: 6px;

    background-color: #FFFFFF;
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    background-size: 16px 16px;

    @include svg((viewBox: 0 0 24 24, "xml:space": preserve)) {
        @include svg('path', (
            fill: #8F8F8F,
            opacity: 0.7,
            d: "M11.688,9.75a6.322,6.322,0,1,0-1.9,1.925l.287-.2L14.6 16l1.4-1.429-4.521-4.525zm-1.8-6.95a5.009,5.009,0,1,1-3.542-1.467A4.977 4.977,0,0,1,9.892,2.8z"
        ));
    }

    &::placeholder {
        color: $input-placeholder-color;
    }

    &:focus {
        border: $tab-border;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        border: $input-border-active;
    }
}
