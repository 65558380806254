@import '../../../variables';

.statsUploadSection {
    display: flex;
    flex-wrap: wrap;
    margin-top: $gutter-width / 2;

    &.noActions {
        margin-top: 0;
    }

    @media (min-width: 600px) {
        margin-top: $gutter-width;
    }

    @media (min-width: 960px) {
        margin-top: $gutter-width * 2;
        align-items: stretch;
    }
}

.statsSection,
.uploadSection {
    $small-width: 100%;
    $large-width: calc(50% - #{$gutter-width / 2});

    max-width: $small-width;
    flex: 0 0 $small-width;

    @media (min-width: 960px) {
        max-width: $large-width;
        flex: 0 0 $large-width;

        &:nth-of-type(n) {
            margin-top: 0;
        }
    }
}

.uploadSection {
    @media (min-width: 960px) {
        margin-left: $gutter-width;
    }
}
