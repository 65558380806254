@import "../../variables";

.container {
    display: flex;

    &.left { justify-content: flex-start; }
    &.center { justify-content: center; }
    &.right { justify-content: flex-end; }
    &.justify { justify-content: space-between; }
}

.label {
    display: block;
    margin: 0px 20px;
    text-transform: uppercase;
    line-height: 1.4em;
    color: #666;
}

.button {
    border: none;
    background: none;
    padding: 0px;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.25;
    }

    &:focus {
        background-color: $tab-color;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        background-color: inherit;
    }
}

.iconLeft, .iconRight {
    display: block;
    width: 25px;
    height: 25px;
    fill: #666;
    margin-bottom: 1px;
}

.iconLeft {
    transform: rotate(90deg);
}

.iconRight {
    transform: rotate(270deg);
}
