@import '../../../variables';
@import '~sassdash/index';
@import '~sass-svg/index';

.columnContainer {
  display: flex;
  justify-content: space-between;
}

.column1, .column2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column1 {
  margin-bottom: 5px;
  width: 50%;
  color: #682f91;
  font-weight: 500;
  text-decoration: none;
}

.column2 {
  width: 50%;
  text-align: right;
}

.searchInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.menu {
  top: 100%;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  scrollbar-color: #682f91 #eee;
  scrollbar-width: thick;

  @media (max-width: 1080px) {
    margin-bottom: 25px;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #eee;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #682f91;
    border-radius: 8px;
    border: 3px solid #eee;
  }
}

.searchInput {
  padding-left: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: $input-border-active;
  background-color: transparent;
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
  background-size: 16px 16px;

  @include svg((viewBox: 0 0 23 23)) {
    @include svg('path', (
      fill: #818181,
      opacity: 1,
      d: "M16.668 14.668h-1.053l-.373-.36a8.691 8.691 0 10-.933.933l.36.373v1.053l5.667 5.667a1.405 1.405 0 001.987-1.987zm-8 0a6 6 0 116-6 5.992 5.992 0 01-6 6z"
    ));
  }
}
