@import '../../../variables';

.section {
    padding: $gutter-width;
    background: #fff;
    box-shadow: $container-shadow;
    border-radius: 4px;
    margin-bottom: $gutter-width;
}

.title {
    width: 100%;
    font-size: 18px;
    font-weight: $heading-font-weight;
    color: $heading-color;
}
