@import '../../variables';

.arrowButton {
    position: absolute;
    top: 0;
    width: ($gutter-width * 2);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    transition: ease 0.5s background-color;

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }

    &.enabled:hover {
        background-color: rgba($color: $grey-light, $alpha: 0.5);

        .paginationPreviousIcon,
        .paginationNextIcon {
            fill: $background-color;
        }
    }

    &.disabled {
        cursor: not-allowed;

        .paginationPreviousIcon,
        .paginationNextIcon {
            fill: rgba($color: #919191, $alpha: 0.5);
        }
    }
}

.paginationPreviousIcon,
.paginationNextIcon {
    display: block;
    width: 22px;
    height: 22px;
    fill: #919191;
    transition: ease 0.5s fill;
}

.pageButtons {
    position: absolute;
    bottom: 0;
    left: ($gutter-width * 2);
    right: ($gutter-width * 2);
    background-color: rgba($color: $grey-light, $alpha: 0.5);
    display: flex;
    justify-content: center;
    padding: ($gutter-width / 3) 0;
}

.paginationNumberButton {
    composes: pagination-number-button from '../Pagination.scss';
    color: $accent-color;
    background-color: rgba($color: #FFF, $alpha: 0.5);

    &:hover {
        color: $accent-color;
        border-color: $accent-color;
        background-color: rgba($color: #FFF, $alpha: 0.5);
    }

    &.current {
        color: #FFF;
        border-color: $accent-color;
        background-color: $accent-color;
    }
}


