@import '../../variables';

.menu {
    position: relative;
    display: flex;
    margin: 0 0 0 -20px;
    padding: 0;
    list-style: none;
}

.menuItem {
    padding: 10px 20px;
    font-weight: 500;
    cursor: pointer;
}

.selectionUnderline {
    position: absolute;
    height: 4px;
    bottom: 0px;
    border-radius: 2px;
    background: $accent-color;
    transition: left ease $ui-animation-duration,
                width ease $ui-animation-duration;
}

.responsiveMenu {
    display: none;

    @media (min-width: 1140px) {
        display: flex;
    }
}

.dropdownMenu {
    @media (min-width: 1140px) {
        display: none;
    }
}
