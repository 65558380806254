@import '../../../variables';

.container {
    display: flex;
    flex-wrap: nowrap;

    h4 {
        color: #757575;
        margin-top: 0;
    }
}

.column {
    min-width: 80px;
    margin: 0 ($gutter-width * 2) $gutter-width 0;

    &.alignRight {
        text-align: right;
    }

    &:last-child {
        margin-right: 0;
    }
}

.innerContainer {
    display: flex;
}
