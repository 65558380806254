@import '../../../variables';

.textArea {
    box-sizing: border-box;
    width: 750px;
    max-width: 100%;
    min-height: 200px;
    padding: ($gutter-width / 3);
    resize: vertical;
    white-space: pre-wrap;
    line-height: 1.5;
    font-size: $base-font-size;
    font-family: $base-font-family;
}

.templateTagWrapper {
    margin: 0 0 $gutter-width;
}

.templateTagList {
    display: flex;
    flex-wrap: wrap;
    margin: ($gutter-width / 3) 0 ($gutter-width / 2);
    padding: 0;
    list-style: none;
    color: #444;
}

.templateTagItem {
    flex-basis: 25%;
    margin: ($gutter-width / 2) 0;
    padding-right: $gutter-width;
}

.tagContainer {
    display: inline-block;
}

.tag {
    position: relative;
    font-style: italic;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        .tooltip {
            visibility: visible;
        }
    }
}

.tooltip {
    position: absolute;
    top: 100%;
    padding: ($gutter-width / 4) ($gutter-width / 2);
    border-radius: ($gutter-width / 4);
    background-color: rgba(0, 0, 0, 0.75);
    color: #CCC;
    white-space: nowrap;
    z-index: 1;
    font-style: normal;
    font-weight: bold;
    pointer-events: none;
    visibility: hidden;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
}

.templateTagsToggle {
    background: transparent;
    border: none;
    padding: 0;
    font-size: $base-font-family;
    font-weight: 700;
    text-transform: uppercase;
    color: #666;
}
