.textWrapper {
  display: flex;
  flex-direction: column;
}

.underline {
  height: 3px;
  width: 100%;
  background: #e2e2e2;
  border-radius: 1.5px;
}

.underlineFill {
  height: 3px;
  width: 0;
  border-radius: 1.5px;
  transition: width 0.6s ease;
}
