.bulkActions {
    display: flex;
    transition: ease 1s all;

    &.hidden {
        opacity: 0;
    }

    &.visable {
        opacity: 1;
    }
}
