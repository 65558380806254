@import '../variables';

.pagination-container {
    display: flex;
    align-items: center;
}

.pagination-number-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin: 0px;
    padding: 0px;
    background: none;
    border: solid 1px #E5E5E5;
    color: #9e9e9e;
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1em;

    transition: color ease $ui-animation-duration,
                border-color ease $ui-animation-duration;

    & + & {
        margin-left: 10px;
    }

    &.current, &:hover {
        color: #141414;
        border-color: #c0c0c0;
    }
}

.pagination-previous-button, .pagination-next-button {
    margin: 0px;
    padding: 0px;
    background: none;
    border: none;

    > svg {
        display: block;
        width: 22px;
        height: 22px;
        fill: #919191;
    }
}

.pagination-previous-button {
    margin-right: 10px;
}

.pagination-next-button {
    margin-left: 10px;
}
