@import '../../../variables';

.payslipTable {
    width: 100%;

    @media (min-width: 600px) {
        width: 80%;
    }

    @media (min-width: 960px) {
        width: 60%;
        min-height: 675px;
    }
}

.buttonLink {
    composes: buttonLink from '../../../components/tables/data-table/DataTable.module.scss';
}

.payslipTray {
    position: absolute;
    top: 0;
    right: $gutter-width;
    width: 50%;
    height: calc(100% + #{$gutter-width});
    z-index: 3;
    overflow: hidden;
    padding: 0 0 $gutter-width $gutter-width;
}

.dateNavigation {
    margin-bottom: 20px;
}
