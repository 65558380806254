.actionMenu {
    opacity: 1;
    transition: ease .5s opacity;

    @media (min-width: 1140px) {
        opacity: 0;
    }
}

:global(.__flag-user-is-tabbing) .actionMenu {
    opacity: 1;
}

.dataTableRow .dataTableCell {
    transition: background 0.5s ease;
}

.dataTableRowSmall {
    height: 1.5;
}

.dataTableRow:hover {
    .dataTableCell {
        transition: background 0.3s ease;
        background-color: #efefef;
    }

    .actionMenu {
        opacity: 1;
    }
}
